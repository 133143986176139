const modalOpen = document.querySelectorAll(".modalOpen");
const modalClose = document.querySelectorAll(".modalClose");
const modalBody = document.querySelector("#modalBody");

modalOpen.forEach((el) => {
  el.addEventListener("click", function (e) {
    e.preventDefault();
    modalBody.classList.remove("hidden");
    console.log("nesto");
  });
});

modalClose.forEach((el) => {
  el.addEventListener("click", function () {
    modalBody.classList.add("hidden");
  });
});

document.onkeydown = (e) => {
  if (e.key === "Escape") {
    modalBody.classList.add("hidden");
  }
};
