window.onload = function () {
  let slides = document.getElementsByClassName("carousel-item"),
    addActive = function (slide) {
      slide.classList.add("active");
    },
    removeActive = function (slide) {
      slide.classList.remove("active");
    };
  addActive(slides[0]);

  let slidesTwo = document.getElementsByClassName("carousel-item-two"),
    addActiveTwo = function (slide) {
      slide.classList.add("active");
    },
    removeActiveTwo = function (slide) {
      slide.classList.remove("active");
    };
  addActiveTwo(slidesTwo[0]);

  setInterval(function () {
    for (let i = 0; i < slides.length; i++) {
      if (i + 1 == slides.length) {
        addActive(slides[0]);
        slides[0].style.zIndex = 100;
        setTimeout(removeActive, 300, slides[i]);
        break;
      }
      if (slides[i].classList.contains("active")) {
        slides[i].removeAttribute("style");
        setTimeout(removeActive, 300, slides[i]);
        addActive(slides[i + 1]);
        break;
      }
    }

    for (let i = 0; i < slidesTwo.length; i++) {
      if (i + 1 == slidesTwo.length) {
        addActiveTwo(slidesTwo[0]);
        slidesTwo[0].style.zIndex = 100;
        setTimeout(removeActiveTwo, 300, slidesTwo[i]);
        break;
      }
      if (slidesTwo[i].classList.contains("active")) {
        slidesTwo[i].removeAttribute("style");
        setTimeout(removeActiveTwo, 300, slidesTwo[i]);
        addActiveTwo(slidesTwo[i + 1]);
        break;
      }
    }
  }, 3000);
};
