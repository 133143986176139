// Hero slider + I screen + II screen
const swiper = new Swiper(".mySwiper", {
  slidesPerView: "auto",
  loop: true,
  centeredSlides: true,
  grabCursor: true,

  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});

// III screen
const swiper2 = new Swiper(".mySwiper2", {
  slidesPerView: "auto",
  loop: true,
  centeredSlides: true,
  grabCursor: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
});
